.icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

.mian {
	user-select: none;
	min-width: 160px;
	min-height: 200px;
	margin: 20px auto;
	text-align: center;
	border: 1px solid #999;
	color: #666;
}

h5 {
	text-align: center;
	width: 100%;
	height: 40px;
	line-height: 40px;
	color: rgb(110, 100, 100);
	background-color: rgb(257, 245, 128);
}

.option {
	width: 100%;
	height: 30px;
	background: rgb(203, 233, 190);
	position: relative;
}

.option .optionbox {
	width: 100%;
	height: 30px;
	display: flex;
	top: 0;
	left: 0;
	flex-direction: row;
	justify-content: space-between;
}

.option .optionbox div {
	align-self: flex-start;
	line-height: 28px;
	font-size: 8px;
	float: left;
	width: 50px;
	height: 28px;
	cursor: pointer;
	border-top: 1px solid #fff;
	border-left: 1px solid #fff;
	border-right: 1px solid #bbb;
	border-bottom: 1px solid #bbb;
}

.option .optiontext {
	display: none;
	width: 100%;
	height: 100%;
	font-size: 8px;
	line-height: 30px;
	color: rgb(194, 156, 99);
	word-wrap: break-word;
}

.yanse {
	color: rgb(255, 171, 75);
}

.head {
	width: 100%;
	height: 30px;
	background: rgb(218, 241, 176);
	position: relative;
}

.mian .head .mine_box {
	line-height: 30px;
	width: 60px;
	height: 100%;
	background-color: rgb(218, 241, 176);
	float: left;
}

.mian .head .againbox {
	color: rgb(255, 162, 56);
	width: 25px;
	height: 25px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.mian .head .again {
	cursor: pointer;
	line-height: 3px;
	font-size: 1.5em;
	width: 25px;
	height: 25px;
}

.mian .head .left {
	display: none;
	position: absolute;
	left: -80px;
	top: 1px;
	width: 100px;
	height: 25px;
	font-size: 1em;
	color: rgb(66, 163, 228);
}

.mian .head .right {
	display: none;
	position: absolute;
	right: -80px;
	top: 1px;
	width: 100px;
	height: 25px;
	font-size: 1em;
	color: rgb(66, 163, 228);
}

.mian .head .time_box {
	line-height: 30px;
	width: 60px;
	height: 100%;
	background-color: rgb(218, 241, 176);
	float: right;
}

.box {
	margin: 0 auto;
	width: 160px;
	height: 160px;
	list-style: none;
	background: rgb(173, 173, 167);
	border: 4px solid #6666;
}

.box > li {
	width: 28px;
	height: 28px;
	border-top: 2px solid #ddd;
	border-left: 2px solid #ddd;
	border-right: 2px solid #666;
	border-bottom: 2px solid #666;
	background: #999;
	float: left;
	color: rgb(255, 144, 124);
	position: relative;
	text-align: center;
	line-height: 28px;
}
